<template lang="html">
    <section class="row">

      <div v-for="option in game.options" :key="option.id" :class="optionSize(game.options.length)">
        <game-option :option="option" :game="game" :event="event" :viewType="viewType"></game-option>
      </div>

    </section>
</template>
<script lang="js">
//import gameOption from './event-card-game-option';
import gameOption from './event-league-game-option';
export default {
  name: 'event-card-game',
  components: {
    gameOption
  },
  props: {
    event: Object,
    game: Object,
    viewType: String
  },
  methods: {
    optionSize(optionLength){
      let size = optionLength == 3 ? 4 : 6;
      return "col-md-" + size;
    }
  }
}
</script>