<template>
<div class="card event-card" :class="[role, {'closed': event.closed }]">
    <div v-if="event.closed" class="card-layer">
        <div>
        <div class="small mb-2 text-warning">Betting closed</div>
        <!--<b-button variant="inverse-info" class="btn-sm" @click.prevent="GoVoteDetail(event.id)"><i class="mdi mdi-playlist-check btn-icon-prepend small"></i> GO TO VOTE</b-button>-->
        </div>
    </div>
    <div class="card-header header-sm">
        <div class="d-flex align-items-center">
            <div class="wrapper d-flex align-items-center media-info">
                <slot name="header-left">
                    <i :class="GetCategoryIcon(event.eventCategoryName)" v-b-tooltip.top :title="event.eventCategoryName" />
                    <h6 class="card-title ml-2 text-muted">{{ GetEventCardTitle(event) }}</h6>
                </slot>
            </div>
            <div class="wrapper ml-auto action-bar">
                <slot name="header-right"></slot>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="card-detail mb-2">

            <slot name="date"></slot>

            <slot name="teams">
            <div v-if="event.teams && event.teams.length == 2">
                <div v-for="team in event.teams" :team="team" :key="team.name" class="d-flex flex-row justify-content-between">
                    <div class="wrapper d-flex align-items-center team-info">
                        <img :src="GetLogoUrl(team.name)" class="img-xxs rounded-circle mb-1" />
                        <h6 class="ml-2 mb-1 text-300">{{team.name}}</h6>
                    </div>
                    <div class="align-self-center">
                        <h6>{{team.score}}</h6>
                    </div>
                </div>
            </div>
            <div v-else>
                <h5>{{ event.games[0].title }}</h5>
            </div>
            </slot>
        </div>
        <slot name="body"></slot>
    </div>
    <div class="card-footer">
        <slot name="footer"></slot>
    </div>
</div>
</template>

<script>
export default {
    data(){
        return {
            voted: false,
            voteButtonStyle: 'outline-warning'
        }
    },
  props: {
    viewType: String,
    role: String,
    event: Object
  }
}
</script>